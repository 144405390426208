<template>
    <div div class="k-flex-col">
        <b-table
            :items="applications"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :busy="isBusy"
            @row-clicked="onRowClicked"
            thead-class="text-center"
            tbody-tr-class="pointer"
            hover
            outlined
            stacked="lg"
        >
            <template #table-busy>
                <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Chargement des données...</strong>
                </div>
            </template>
        </b-table>
        <div>
            <b-pagination
                class="m-auto"
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
                first-number
                last-number
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
export default {
    props: {
        applications: { type: Array, required: false },
    },
    data: function() {
        return {
            perPage: 10,
            currentPage: 1,
            fields: [
                {
                    key: "name",
                    label: "Nom",
                    sortable: true,
                    tdClass: "font-weight-bold text-kalkin-1",
                },
                {
                    key: "type",
                    label: "Type",
                    sortable: true,
                    tdClass: "text-center",
                },
                {
                    key: "template",
                    label: "Template",
                    sortable: true,
                    tdClass: "text-center",
                },
                {
                    key: "createdAt",
                    label: "Date de création",
                    sortable: true,
                    formatter: Vue.filter("dateFromISO"),
                    tdClass: "text-center",
                },
            ],
        };
    },
    computed: {
        isBusy() {
            return this.applications === null;
        },
        totalRows() {
            return this.applications ? this.applications.length : 0;
        },
    },
    methods: {
        onRowClicked(item) {
            this.$emit("item-clicked", item);
        },
        onDelete(item) {
            this.$emit("item-delete", item);
        },
    },
};
</script>

<style scoped></style>
