<template>
    <div class="k-page-flex">
        <div class="k-page-title">
            <h1>Applications</h1>
            <b-button
                size="sm"
                variant="outline-kalkin-2"
                v-b-modal.ModalCreateApplication
            >
                <b-icon-plus-circle /> Nouvelle application
            </b-button>
        </div>

        <div class="k-page-body">
            <TableApplication
                :applications="applications"
                @item-clicked="onApplicationSelected"
            />
        </div>

        <ModalCreateApplication @saved="onApplicationCreated" />
    </div>
</template>

<script>
import TableApplication from "@/components/model/application/TableApplication";
import ModalCreateApplication from "@/components/model/application/ModalCreateApplication";
export default {
    components: {
        TableApplication,
        ModalCreateApplication,
    },

    data: function() {
        return {
            applications: null,
        };
    },

    methods: {
        async fetchApplications() {
            this.applications = await this.$store.dispatch(
                "application/getApplications",
            );
        },

        onApplicationSelected(item) {
            this.$router.push({
                name: "AdminApplication",
                params: {
                    applicationId: item.id,
                },
            });
        },

        async onApplicationCreated() {
            this.fetchApplications();
        },
    },

    async beforeMount() {
        this.fetchApplications();
    },
};
</script>
