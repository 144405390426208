<template>
    <b-modal
        id="ModalCreateApplication"
        size="xl"
        title="Créer une application"
        header-bg-variant="kalkin-1"
        header-text-variant="light"
        hide-footer
    >
        <FormApplication @saved="onSaved" class="mb-4" />
    </b-modal>
</template>
<script>
import FormApplication from "@/components/model/application/FormApplication";
export default {
    components: {
        FormApplication,
    },
    methods: {
        onSaved() {
            this.$bvModal.hide("ModalCreateApplication");
            this.$emit("saved");
        },
    },
};
</script>
